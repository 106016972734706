<template>
  <v-container fluid>
    <v-overlay
        z-index="100"
        :value="overlay"
    >
      <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
      />
    </v-overlay>
    <v-row>
      <v-col>
        <h1 class="mb-1">Analytics</h1>
      </v-col>
    </v-row>
    <!-- <v-container fluid> -->
      <v-row align="center">
      <v-col md="4" class="my-4">
          <v-card
            class="mx-auto"
          >
            <v-card-title>Total Verifications</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>{{ verificationResults.verified + verificationResults.not_verified +
            verificationResults.pending + verificationResults.incomplete}}</h1></v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card
            class="mx-auto"
          >

            <v-card-title>Verified</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>{{ verificationResults.verified }}</h1></v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card
            class="mx-auto"
          >

            <v-card-title>Not Verified</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>{{ verificationResults.not_verified }}</h1></v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card
            class="mx-auto"
          >

            <v-card-title>Pending</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>{{ verificationResults.pending }}</h1></v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card
            class="mx-auto"
          >
            <v-card-title>Incomplete</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>{{ verificationResults.incomplete }}</h1></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    <v-card  class="mb-4">
      <v-container fluid>
      <v-row align="center">
        <v-col md="6">
            <v-col>

              <v-card-title>ID Document Types</v-card-title>

              <v-divider class="mx-4 mb-4"></v-divider>
              <apexchart ref="successChart" height="250" width="550" type="donut"
                         :options="cardTypeChartOptions" :series="cardTypeSeries"></apexchart>
            </v-col>
        </v-col>
        <v-col md="6">
            <v-col>

              <v-card-title>Auto / Manual</v-card-title>

              <v-divider class="mx-4 mb-4"></v-divider>

              <apexchart ref="autoChart" height="250" width="550" type="donut"
                         :options="autoChartOptions" :series="autoSeries"></apexchart>
            </v-col>
        </v-col>
      </v-row>
  </v-container>

    </v-card>
  <!-- </v-container> -->
    <v-card class="mb-4">
      <v-container fluid>
        <v-row>
          <v-col
              class="d-flex"
              cols="12"
              sm="6">
            <v-card-title>Verification Time</v-card-title>

          </v-col>
          <v-col
              class="d-flex"
              cols="12"
              sm="3"
          >
            <v-select
                :items="months"
                label="Select Month"
                dense
                solo
                v-model="avgMonth"
                @change="getData(avgMonth, avgYear, true)"
            ></v-select>
          </v-col>
          <v-col
              class="d-flex"
              cols="12"
              sm="3"
          >
            <v-select
                :items="years"
                label="Select Year"
                dense
                solo
                v-model="avgYear"
                @change="getData(avgMonth, avgYear, true)"
            ></v-select>
          </v-col>
        </v-row>
            <v-divider class="mx-4 mb-4"></v-divider>

        <v-row>
          <v-col>
            <apexchart ref="averageChart" height="450" type="line"
                       :options="options" :series="series"></apexchart>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col
              class="d-flex"
              cols="12"
              sm="6">
            <v-card-title>Total Verifications</v-card-title>

          </v-col>
          <v-col
              class="d-flex"
              cols="12"
              sm="3"
          >
            <v-select
                :items="months"
                label="Select Month"
                dense
                solo
                v-model="month"
                @change="getData(month, year, false, true)"
            ></v-select>
          </v-col>
          <v-col
              class="d-flex"
              cols="12"
              sm="3"
          >
            <v-select
                :items="years"
                label="Select Year"
                dense
                solo
                v-model="year"
                @change="getData(month, year, false, true)"
            ></v-select>
          </v-col>
        </v-row>
            <v-divider class="mx-4 mb-4"></v-divider>

        <v-row>
          <v-col>
            <apexchart ref="verificationChart" height="450"
                       :options="productOptions" :series="productSeries" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import Vue from "vue";
import {mapActions} from "vuex";

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
export default {
  name: "Analytics",
  data() {
    return {
      month: null,
      year: null,
      avgMonth: null,
      avgYear: null,
      allRecMonth: null,
      allRecYear: null,
      overlay: true,
      verificationResults: {
        verified: 0,
        not_verified: 0,
        incomplete: 0,
        pending: 0,
        card_types: {},
        auto: 0,
        manual: 0
      },
      months: [
        { value: 1, text: "January", },
        { value: 2, text: "February", },
        { value: 3, text: "March", },
        { value: 4, text: "April", },
        { value: 5, text: "May", },
        { value: 6, text: "June", },
        { value: 7, text: "July", },
        { value: 8, text: "August", },
        { value: 9, text: "September", },
        { value: 10, text: "October", },
        { value: 11, text: "November", },
        { value: 12, text: "December", },
      ],
      years: [
        { value: 2020, text: "2020", },
        { value: 2021, text: "2021", },
        { value: 2022, text: "2022", },
        { value: 2023, text: "2023", },
        { value: 2024, text: "2024", },
      ],
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            format: 'MMM dd',
            showDuplicates: false,
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px',
            }
          }
        },
        legend: {
          position: 'left',
          offsetY: 40,
          fontSize: '16px'
        },
        noData: {
          text: 'Loading...'
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: '#e30000',
              label: {
                borderColor: '#e30000',
                style: {
                  color: '#fff',
                  background: '#e30000'
                },
                text: 'Average'
              }
            }
          ]
        }
      },
      allRecOptions: {
        xaxis: {
          type: 'datetime',
          categories: []
        },
        legend: {
          position: 'left',
          offsetY: 40,
        },
        noData: {
          text: 'Loading...'
        },
        stroke: {
          width: 3
        }
      },
      cardTypeSeries: [],
      autoSeries: [],
      cardTypeChartOptions: {
        labels: [],
      },
      autoChartOptions: {
        labels: ['Auto', 'Manual'],
      },
      series: [
        {
          name: 'Average',
          data: []
        },
        {
          name: 'Minimum',
          data: []
        },
        {
          name: 'Maximum',
          data: []
        },
      ],
      allRecSeries: [
        {
          name: 'All Series',
          data: []
        }
      ],
      productSeries: [{
        name: 'Verified',
        data: []
      },
        {
        name: 'Not Verified',
        data: []
      },
      {
        name: 'Pending',
        data: []
      },
      {
        name: 'Incomplete',
        data: []
      }
      ],
      productOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10
          },
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            format: 'MMM dd',
            showDuplicates: false,
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px',
            }
          }
        },
        legend: {
          position: 'left',
          offsetY: 40,
          fontSize: '16px'
        },
        noData: {
          text: 'Loading...'
        },
        fill: {
          opacity: 1
        },
        colors: ['#004aad', '#db0049', '#ffc107', '#afa9ad']
      }
    }
  },
  mounted() {
    this.getVerificationResults()
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()+1
    this.year = currentYear
    this.month = currentMonth
    this.avgYear = currentYear
    this.avgMonth = currentMonth
    this.allRecYear = currentYear
    this.allRecMonth = currentMonth
    this.getData(this.month, this.year, true, true)
  },
  methods: {
    ...mapActions(["setNotification"]),
    calculateAverage(arr) {
      const sum = arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      return (sum / arr.length) || 0;
    },
    changeDateFormat(date) {
      const splitDate = date.slice(0, 10)
      return new Date(splitDate).getTime()
    },
    updateVerificationChart(dates, seriesData) {
      if (this.$refs.verificationChart !== null && this.$refs.verificationChart !== undefined) {
        this.$refs.verificationChart.updateOptions({
          xaxis: {
            categories: dates
          }
        })
        this.$refs.verificationChart.updateSeries([{
          name: 'Verified',
          data: seriesData[0]
        },
          {
            name: 'Not Verified',
            data: seriesData[1]
          },
          {
            name: 'Pending',
            data: seriesData[2]
          },
          {
            name: 'Incomplete',
            data: seriesData[3]
          }], false, true);
        if (dates.length === 0) {
          this.$refs.verificationChart.updateOptions({
            noData: {
              text: 'No Data!!!'
            }
          })
        }
      }
    },
    updateAverageTimeChart(dates, averageTime) {
      if (this.$refs.averageChart !== null && this.$refs.averageChart !== undefined) {
        this.$refs.averageChart.updateOptions({
          xaxis: {
            categories: dates
          },
          annotations: {
            yaxis: [
              {
                y: this.calculateAverage(averageTime[0]).toFixed(2),
                borderColor: '#e30000',
                label: {
                  borderColor: '#e30000',
                  style: {
                    color: '#fff',
                    background: '#e30000'
                  },
                  text: 'Average'
                }
              }
            ]
          }
        })
        this.$refs.averageChart.updateSeries([
          {
            name: 'Average',
            data: averageTime[0]
          },
          {
            name: 'Minimum',
            data: averageTime[1]
          },
          {
            name: 'Maximum',
            data: averageTime[2]
          },
        ], false, true);
        if (dates.length === 0) {
          this.$refs.averageChart.updateOptions({
            noData: {
              text: 'No Data!!!'
            }
          })
        }
      }
    },
    getData(month, year, updateAverage=false, updateVerification=false) {
      if (updateAverage && this.$refs.averageChart !== null && this.$refs.averageChart !== undefined) {
        this.$refs.averageChart.updateSeries([
          {
            name: 'Average',
            data: []
          },
          {
            name: 'Minimum',
            data: []
          },
          {
            name: 'Maximum',
            data: []
          },
        ], false, true);
        this.$refs.averageChart.updateOptions({
          noData: {
            text: 'Loading!!!'
          }
        })
      }
      if (updateVerification && this.$refs.verificationChart !== null && this.$refs.verificationChart !== undefined) {
        this.$refs.verificationChart.updateSeries([{
          name: 'Verified',
          data: []
        },
          {
            name: 'Not Verified',
            data: []
          },
          {
            name: 'Incomplete',
            data: []
          }], false, true);
        this.$refs.verificationChart.updateOptions({
          noData: {
            text: 'Loading!!!'
          }
        })
      }
      let dates = []
      // let allDates = []
      // let allRecs = []
      let statusNumbers = [[], [], [], []]
      let timeConsumed = []
      let query = `year=${year}`
      if (month) {
        query += `&month=${month}`
      }
      this.$http.get(`/sessions/?${query}`).then(res=>{
        // console.log(res.data)
        if (res.data && res.data.length > 0) {
          res.data.forEach(x => {
            if (x.created_at) {
              const date = this.changeDateFormat(x.created_at)
              // allDates.push(date)
              // allRecs.push(x.time_consumed)
              if (!dates.includes(date)) {
                dates.push(date)
                statusNumbers[0].push(0)
                statusNumbers[1].push(0)
                statusNumbers[2].push(0)
                statusNumbers[3].push(0)
                timeConsumed.push([])
              }
              const dateIndex = dates.indexOf(date)
              if (updateVerification) {
                if (x.status === "Verified") {
                  statusNumbers[0][dateIndex] += 1
                } else if (x.status === "Not Verified") {
                  statusNumbers[1][dateIndex] += 1
                } else if (x.status === "Pending") {
                  statusNumbers[2][dateIndex] += 1
                } else {
                  statusNumbers[3][dateIndex] += 1
                }
              }
              timeConsumed[dateIndex].push(x.time_consumed)
            }
          })
        }
        if (updateVerification) {
          this.updateVerificationChart(dates, statusNumbers)
        }
        if (updateAverage) {
          let averageTime = [[], [], []]
          timeConsumed.forEach(x => {
            const avg = this.calculateAverage(x).toFixed(2),
                min = Math.min.apply(null, x).toFixed(2),
                max = Math.max.apply(null, x).toFixed(2);
            averageTime[0].push(avg)
            averageTime[1].push(min)
            averageTime[2].push(max)
          })
          this.updateAverageTimeChart(dates, averageTime)
        }

        // console.log(dates)
        // console.log(statusNumbers)
        // this.updateSeriesData(statusNumbers, dates, averageTime, updateAverage, updateVerification)
      }).catch((error) => {
        console.log(error.stack)
        this.setNotification({
          message: error,
          color: 'error'
        })
      })
    },
    getVerificationResults() {
      this.$http.get(`/verification-results`).then(res=> {
        let cardTypes = []
        let cardNums = []
        this.verificationResults = res.data
        if (this.verificationResults && Object.keys(this.verificationResults.card_types).length > 0) {
          Object.keys(this.verificationResults.card_types).forEach(type => {
            cardTypes.push(type)
            cardNums.push(this.verificationResults.card_types[type])
            if (this.$refs.successChart !== null && this.$refs.successChart !== undefined) {
              this.$refs.successChart.updateOptions({ labels: cardTypes })
              this.$refs.successChart.updateSeries(cardNums, false, true);
            }
          })
          this.updateVerificationResults(res.data)
        } else {
          console.log("No data!!!!!!!!!!")
          if (this.$refs.successChart !== null && this.$refs.successChart !== undefined) {
            this.$refs.successChart.updateOptions({
              noData: {
                text: 'No Data!!!'
              }
            })
          }
          if (this.$refs.autoChart !== null && this.$refs.autoChart !== undefined) {
            this.$refs.autoChart.updateOptions({
              noData: {
                text: 'No Data!!!'
              }
            })
          }
          this.overlay = false
        }
      }).catch((error) => {
        console.log(error.stack)
        this.setNotification({
          message: error,
          color: 'error'
        })
      })
    },
    updateVerificationResults(results) {
      this.$refs.autoChart.updateSeries([results.auto, results.manual], false, true);
      this.overlay = false
    }
  },
}
</script>

<style scoped>

</style>